import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.css';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const NavBar = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const hideComponent = location.pathname === '/bookNow';
  return (

    <div className=' sticky-top' id="csquare-nav">

      <Navbar expand="lg" className={styles.navbar + " p-md-0 navbar-dark"}>
        <Container fluid="xl" className={"align-items-start"}>
          <div className='w-25'> <Navbar.Brand href="/">
            <img
              src="celebrations-square-logo.svg"
              width="200px"
              className="d-inline-block align-top d-none d-lg-block"
              alt="Celebrations Square" style={{ filter: "contrast(2)" }}
            />
            <img src={require('./images/logo-mobile.png')} className='d-lg-none w-75' style={{ filter: "contrast(2)" }} alt='Celebrations square'></img>
          </Navbar.Brand>

          </div>
          <div className=''>
            <div className={styles.navBarTopSection + " d-lg-flex py-sm-3  d-none d-lg-block align-items-center"}>
              <div className='px-sm-2'><i className="bi bi-geo-alt-fill me-md-2"></i><a href="https://maps.app.goo.gl/XPEPZoiUdKCPS2xE8" target='_blank' rel='noreferrer'>Hyderabad</a></div>
              <div className='px-sm-2'><i className="bi bi-envelope-at-fill me-md-2"></i><a href='mailto:info@celebrationssquare.com&subject=Booking%20Private%20Theatre%20for%20Celebrations:%20Availability%20Inquiry%20&%20Reservation%20Details%20Needed&body=I%20would%20like%20to%20book%20a%20private%20theatre%20for%20a%20celebration! Please%20let%20me%20know%20about%20availability%20and%20the%20reservation%20process. '>info@celebrationssquare.com</a></div>
              <div className='px-sm-2'><i className="bi bi-whatsapp me-md-2"></i><a href="https://wa.me/8143678222?text=Hi%2C%20I%20want%20to%20book%20the%20slot" target="_blank" rel="noreferrer" className="ml-2 mr-2">+91 8143678222 (WhatsApp only)</a> </div>
              <ul className='list-unstyled me-2 cs2-social-links'><li className='list-inline-item'> <a href="https://www.instagram.com/celebrations_square/" target="_blank" rel="noreferrer"><i className="bi bi-instagram text-light fs-5"></i>
 </a></li><li className='list-inline-item'><a href="https://www.youtube.com/@Celebrationssquare" target="_blank" rel="noreferrer"><i className="bi bi-youtube link-primary text-light fs-5"></i></a></li><li className='list-inline-item text-light fs-5'><a href="https://www.facebook.com/people/Celebrationssquare/61558146910554/" target="_blank" rel="noreferrer"><i className="bi bi-facebook text-light fs-5"></i></a></li></ul>

            </div>
            <div className='d-flex flex-column mt-md-5'> <Navbar.Toggle data-toggle="slide-collapse" data-target="#slide-navbar-collapse" aria-expanded="false"
              aria-controls="basic-navbar-nav" className='mb-2' />
              <Navbar.Collapse id="slide-navbar-collapse" className="collapse navbar-collapse">
                <Navbar.Toggle type="button" className="btn-close close-icon" aria-label="Close" style={{ position: "relative", left: "-15px", top: '-5px' }} data-toggle="slide-collapse"></Navbar.Toggle>
                <Nav className={styles.leftNav}>
                  <Nav.Link as={NavLink} to="/" className={styles.navLink}>
                    Home
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/about" className={styles.navLink}>
                    About
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/gallery" className={styles.navLink}>
                    Gallery
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/refundpolicy" className={styles.navLink}>
                    Refund Policy
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/addons" className={styles.navLink}>
                    Add-ons
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/booknow" className={styles.navLink + " btn btn-danger text-white  d-none d-lg-block"} type='button'>
                    Book Now
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>

          </div>
        </Container>
      </Navbar>
      <div className='px-3 py-2 d-lg-none d-flex justify-content-between' style={{ backgroundColor: "#4D4637" }}>
        <div>
          <a href="https://maps.app.goo.gl/XPEPZoiUdKCPS2xE8" target='_blank' rel='noreferrer'><i className="bi bi-geo-alt-fill fs-3 logo-color-2"></i></a> <a href="https://www.instagram.com/celebrations_square/" target="_blank" rel
          ="noreferrer" className='fs-3 logo-color-2'><i className="bi bi-instagram  fs-3 logo-color-2 mx-2"></i></a> <a href="https://wa.me/8143678222?text=Hi%2C%20I%20want%20to%20book%20the%20slot"><i className="bi bi-whatsapp mx-2 fs-3 logo-color-2"></i></a>
        </div>
        <a onClick={() => navigate("/bookNow")} className="btn btn-danger d-lg-none fs-6 " style={{ display: hideComponent ? 'none' : 'block' }}>
          Book Now
        </a>
      </div>
      {/* { <marquee className="bg-color-1 text-white fs-5 py-2" direction="left" scrollamount="10">
    <i className="bi bi-megaphone-fill"></i> For all IPL match bookings please contact our customer care (+91 8143 678 222/444).
    </marquee> } */}
      <div className="menu-overlay"></div>
    </div>
  );
};

export default NavBar;
